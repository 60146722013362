import { Fragment, useCallback, useContext, useEffect } from "react";
import NavBar from "../components/NavBar";
import LandHome from "../components/LandHome";
import AboutUs from "../components/AboutUs";
import HireComp from "../components/HireComp";
import CareerSlide from "../components/CareerSlide";
import AllWork from "../components/AllWork";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import DBS from "../components/DBS";
import Product from "../components/Product";
import MobDBS from "../components/MobDBS";
import SpecialAbout2 from "../components/SpecialAbout2";
import SpecialAbout from "../components/SpecialAbout";
import NavigateTop from "../UI/NavigateTop";
import StateContext from "../context/StateContext";

const MainApp = () => {
  const { hideAvailableBar } = useContext(StateContext);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      hideAvailableBar();
    }, 3000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [hideAvailableBar]);

  return (
    <Fragment>
      <NavigateTop />
      <NavBar />
      <LandHome />
      <AboutUs />
      <HireComp />
      <Product />
      <DBS />
      <MobDBS />
      <CareerSlide />
      <AllWork />
      <SpecialAbout2 />
      <SpecialAbout />
      <ContactUs />
      <Footer />
    </Fragment>
  );
};

export default MainApp;
