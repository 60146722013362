import customer from "../images/customer.png";

const HireComp = () => {
  return (
    <div className="xl:px-40 lg:px-20 md:px-12 px-8 md:py-24 pt-16 pb-6 md:pb-2 xl:pb-24 lg:pb-16 relative bg-[rgba(0,0,0,0.7)]">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-x-36 md:gap-x-28 lg:gap-x-6 land-home-head">
        <div className="text-3xl font-extrabold text-white">
          <h2>Client satisfaction is our top priority</h2>
        </div>
        <div className="text-sm mt-4 md:mt-0 lg:ml-8 xl:ml-0 text-gray-100">
          <p>
          We understand that happy clients are the foundation of a successful business. 
          By making client satisfaction our utmost focus, we strive to
          deliver outstanding results, and ensure that every interaction with our clients is a positive and rewarding.
          </p>
        </div>
      </div>
      <div className="center-div lg:block hidden top-[50%] left-[80%]">
        <div>
          <img src={customer} alt="customer" width={300} />
        </div>
      </div>
      <div className="flex justify-center lg:hidden">
        <div>
          <img src={customer} alt="customer" width={250} />
        </div>
      </div>
    </div>
  );
};

export default HireComp;
