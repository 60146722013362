import MainApp from "./components/MainApp";
import VacancyDetail from "./components/vacancy-detail/VacancyDetail";
import { Routes, Route } from "react-router-dom";
import { fullStackVacancy, graphicVacancy } from "./static_data/data";

const App = () => {
  return (
    <>
      <div className="overflow-hidden">
        <Routes>
          <Route path="/" element={<MainApp />}></Route>
          <Route
            path="/vacancy/full-stack"
            element={<VacancyDetail vacancy={fullStackVacancy} />}
          ></Route>
          <Route
            path="/vacancy/graphic-designer"
            element={<VacancyDetail vacancy={graphicVacancy}/>}
          ></Route>
        </Routes>
      </div>
    </>
  );
};

export default App;
