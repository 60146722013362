import { useContext } from "react";
import { RxDoubleArrowLeft } from "react-icons/rx";
import StateContext from "../context/StateContext";
import { useNavigate } from "react-router-dom";

const LearnButton = ({ className, classOfBtn, link }) => {
  const { showAvailableBar } = useContext(StateContext);
  const navigate = useNavigate();
  const handleNavigation = (link) => {
    window.open(`/vacancy/${link}`, '_blank');
    // window.scrollTo(0, 0);
  };
  return (
    <div
      className={`my-theme-color font-bold land-home-head ${className} relative inline-block learn-div-button`}
      onClick={link ? () => handleNavigation(link) : () => showAvailableBar()}
    >
      <button type="button" className="inline-block">
        <div className="flex items-center inline-block">
          <div className={`text-2xl inline-block ${classOfBtn} pr-1`}>
            <RxDoubleArrowLeft />
          </div>
          <div className="uppercase px-2.5">Learn More</div>
        </div>
      </button>
    </div>
  );
};

export default LearnButton;
