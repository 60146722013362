import { BsRocketTakeoff } from "react-icons/bs";
import { TbAwardFilled } from "react-icons/tb";
import { FaUsers, FaEdit } from "react-icons/fa";

const SpecialAbout2 = () => {
  return (
    <div className="xl:px-40 lg:px-20 lg:py-28 land-home-head hidden md:block" id="why-quartz">
      <div className="xl:px-40 lg:py-20 px-8 py-12 md:px-12 md:py-16 lg:rounded-3xl why-quartz-pc video-content">
        <div>
          <div className="text-3xl font-medium text-white break-all">
            <h2>Why Quartz is best choice for software development services</h2>
          </div>
          <div className="text-md mt-4 text-gray-300">
            <p>
            We leverage trending technologies and data analytics to optimize your business operations, providing tailored solutions to meet your unique needs. 
            We recognize that in today's technologically driven world, leveraging digital tools and strategies is not just a convenience but a necessity for business growth and survival.
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 mt-8">
          <div className="bg-gray-50 p-8 rounded-2xl expertise">
            <div className="flex justify-center text-3xl">
              <div className="bg-[#fc0235] p-3 rounded-full text-white">
                <BsRocketTakeoff />
              </div>
            </div>
            <div className="font-bold text-center mt-4 text-lg">
              <h3>Expertise</h3>
            </div>
            <div className="mt-3 text-sm">
              <p>
                Our team consists of highly skilled developers and designers
                with years of experience in the industry. We stay up-to-date
                with the latest technologies and methodologies to ensure that we
                are providing our clients with the best possible solutions.
              </p>
            </div>
          </div>
          <div className="bg-gray-50 p-8 rounded-2xl">
            <div className="flex justify-center text-3xl">
              <div className="bg-[#fc0235] p-3 rounded-full text-white">
                <TbAwardFilled />
              </div>
            </div>
            <div className="font-bold text-center mt-4 text-lg">
              <h3>Quality</h3>
            </div>
            <div className="mt-3 text-sm">
              <p>
                We believe in delivering high-quality software solutions that
                are scalable, secure, and easy to maintain. We rigorously test
                our software to ensure that it meets our clients' requirements
                and exceeds their expectations.
              </p>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 mt-10">
          <div className="bg-gray-50 p-8 rounded-2xl">
            <div className="flex justify-center text-3xl">
              <div className="bg-[#fc0235] p-3 rounded-full text-white">
                <FaEdit />
              </div>
            </div>
            <div className="font-bold text-center mt-4 text-lg">
              <h3>Customization</h3>
            </div>
            <div className="mt-3 text-sm">
              <p>
                We understand that every business is unique, and we approach
                each project with a customized solution tailored to your
                specific needs that will help you achieve your goals.
              </p>
            </div>
          </div>
          <div className="bg-gray-50 p-8 rounded-2xl">
            <div className="flex justify-center text-3xl">
              <div className="bg-[#fc0235] p-3 rounded-full text-white">
                <FaUsers />
              </div>
            </div>
            <div className="font-bold text-center mt-4 text-lg">
              <h3>Customer Service</h3>
            </div>
            <div className="mt-3 text-sm">
              <p>
                We prioritize customer satisfaction and believe in building
                long-lasting relationships with our clients. We are always
                available to answer your questions and provide support
                throughout the software development process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialAbout2;
