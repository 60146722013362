const ContactOption = ({icon, title, info, className}) => {
  return (
    <div className={`${className} mt-8`}>
      <div className="flex land-home-head">
        <div className="my-theme-color text-xl">{icon}</div>
        <div className="px-5">
            <div className="my-theme-color">{title}</div>
            <div>{info}</div>
        </div>
      </div>
    </div>
  );
};

export default ContactOption;
