import logo from "../images/logo.png";
import { navOptions } from "../static_data/data";
import linkedin from "../images/linkedin.png";
import facebook from "../images/facebook.png";
import { useLocation } from "react-router-dom";
import { BsBoxArrowLeft } from "react-icons/bs";
const Footer = () => {
  const location = useLocation();
  return (
    <div className="xl:px-40 lg:px-20 px-8 h-full md:mt-8 border-t-2 bg-[rgba(0,0,0,0.9)]">
      <div className="lg:flex justify-between pt-12 pb-10 border-b">
        <div className="flex items-center">
          <img src={logo} alt="logo" width={40} />
          <span className="text-heading font-semibold px-2 uppercase">
            Quartz Technology
          </span>
        </div>
        <div className="mt-4 lg:mt-0 md:mt-6">
          <nav>
            <ul className="md:flex items-center text-sm text-gray-400 font-semibold">
              {location.pathname === "/" &&
                navOptions.map((option, index) => {
                  return (
                    <li
                      className="uppercase md:px-4 py-2 md:py-0 cursor-pointer"
                      key={index}
                    >
                      <a href={option.link.pc} className="md:block hidden">
                        {option.name}
                      </a>
                      <a href={option.link.mobile} className="md:hidden block">
                        {option.name}
                      </a>
                    </li>
                  );
                })}
              {(location.pathname === "/vacancy/full-stack" ||
                location.pathname === "/vacancy/graphic-designer") && (
                <a href="/">
                  <li className="flex items-center">
                    <div className="text-2xl">
                      <BsBoxArrowLeft />
                    </div>
                    <div className="pl-2">GO TO HOMEPAGE</div>
                  </li>
                </a>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <div className="text-gray-400 md:flex items-center justify-between py-5 text-center md:text-left">
        <div>
          <p>© Copyright Quartz Technology, 2023</p>
        </div>
        <div className="flex text-2xl justify-center mt-4 md:mt-0">
          <a
            href="https://www.linkedin.com/company/quartz-technology-nepal"
            className="text-blue-600"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="linkedin" width={25} />
          </a>
          <a
            href="https://www.facebook.com/quartz.technology"
            className="pl-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" width={30} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
