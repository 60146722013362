import { CgWebsite } from "react-icons/cg";
import { SiConsul, SiPaloaltosoftware } from "react-icons/si";
import { MdGraphicEq } from "react-icons/md";

import js from "../images/frontend/js.svg";
import css from "../images/frontend/css.svg";
import html from "../images/frontend/html.svg";
import reactimage from "../images/frontend/reactimage.svg";
import reduximage from "../images/frontend/icons8-redux.svg";

import node from "../images/backend/nodejs.svg";
import core from "../images/backend/.net-framework.svg";


import firebase from "../images/database/firebase.svg";
import mongo from "../images/database/mongo.svg";
import sql from "../images/database/mysql.svg";

import amazon from "../images/server/amazon.svg";
import docker from "../images/server/docker.svg";
import kubernates from "../images/server/kubernated.svg";

import figma from "../images/design/figma.svg";

export const aboutInfo = [
  {
    heading: "Website Development",
    info: "Our experienced team specializes in creating stunning and functional websites tailored to meet your specific business needs. We employ the latest web development technologies to deliver high-quality, responsive websites that provide a seamless user experience across all devices.",
    icon: <CgWebsite />,
    delayInAnimation: 0.4,
  },
  {
    heading: " Custom Software Development",
    info: "We offer custom software development services to help you streamline your business processes and enhance operational efficiency. Our skilled developers work closely with you to understand your requirements and build scalable, secure, and intuitive software solutions that address your unique challenges.",
    icon: <SiPaloaltosoftware />,
    delayInAnimation: 0.6,
  },
  {
    heading: "Technical Management Consulting",
    info: "Our technical management consulting services are designed to assist businesses in leveraging technology effectively. We provide expert guidance and strategic advice to help you optimize your IT infrastructure, align technology with your business objectives, and enhance overall performance and productivity.",
    icon: <SiConsul />,
    delayInAnimation: 0.8,
  },
  {
    heading: "UI/UX Design",
    info: "Our creative UI/UX designers focus on crafting visually appealing and user-friendly interfaces that leave a lasting impression. We combine user-centered design principles with the latest industry trends to create intuitive and engaging experiences across various digital platforms.",
    icon: <MdGraphicEq />,
    delayInAnimation: 1,
  },
];

export const navOptions = [
  {
    name: "About",
    link: { pc: "#about-us", mobile: "#about-us" },
  },
  {
    name: "Careers",
    link: { pc: "#careers", mobile: "#careers" },
  },
  {
    name: "Why Quartz",
    link: { pc: "#why-quartz", mobile: "#why-quartz-2" },
  },
  {
    name: "Contact us",
    link: { pc: "#contact-us", mobile: "#contact-us" },
  },
];

export const careerInfo = [
  {
    title: "Full Stack Developer",
    date: "Deadline: n/a",
    info: "We are seeking a experienced Full Stack Developer to join our dynamic team. As a Full Stack Developer, you will play a crucial role...",
    link: "full-stack",
  },
  {
    title: "UI/UX Designer",
    date: "Deadline: n/a",
    info: "We are in search of a UI/UX Designer who can make our web application interfaces both appealing and user-friendly...",
    link: "graphic-designer",
  },
  {
    title: "React Developer",
    date: "Deadline: n/a",
    info: "As a React front-end developer, you will be responsible for translating design mockups and wireframes...",
    status: "Recruited",
  },
];

export const workLogo = [
  {
    frontend: [html, css, js, reactimage, reduximage],
  },
  {
    backend: [node, core],
  },
  {
    database: [firebase, mongo, sql],
  },
  {
    design: [figma],
  },
  // {
  //   mobile: [],
  // },
  {
    server: [amazon, docker, kubernates],
  }
];

export const fullStackVacancy = {
  title: "Full-Stack Developer",
  overview:
    "We are seeking a experienced Full Stack Developer to join our dynamic team. As a Full Stack Developer, you will play a crucial role in designing, developing, and maintaining our web applications. From crafting user interfaces to implementing server-side logic and database management, you will be involved in the entire development lifecycle.",
  resp: [
    "Developing robust and scalable web applications by implementing suitable user interfaces, integrating APIs, and ensuring smooth user experiences.",
    "Writing clean, reusable functional code on the front and back end.",
    "Effectively manage application state, including handling actions, reducers, and selectors using tools like Redux.",
    "Designing and implementing RESTful APIs using Node.js and frameworks like Express.js.",
    "Creating database schemas that represent and support business processes, write efficient queries, and ensure data integrity and security.",
    "Write unit tests and perform debugging to ensure the quality and reliability of your code using tools like Jest or Mocha.",
    "Work closely with other developers, designers, and stakeholders in an agile development environment.",
    "Stay updated with the latest trends, tools, and technologies in the React, Redux, and Node.js ecosystem.",
    "Responsible for identifying and resolving issues that arise during development or in production environments.",
    "Create and maintain thorough documentation related to the development process, codebase, APIs, and project specifications."
  ],
  req_exp: [
   "Should have a bachelors degree in a relevant subject, though we understand that many excellent programmers are self-taught or have unconventional education backgrounds.",
   "Should have strong JavaScript skills and a deep understanding of its features, quirks, and best practices. Experience with ES6+ is a must.",
   "Should have atleast 3 years of experience with React.js. This includes an understanding of React Hooks, Component Life Cycle, and best practices for performance and reusability.",
   "Should ave a deep understanding of state management libraries like Redux. Experience with middleware like Redux-Saga or Redux-Thunk would be beneficial.",
   "Candidates should have a solid understanding of Node.js and Express.js. This includes building RESTful APIs, working with asynchronous programming, and understanding Node.js' event-driven architecture.",
   "Experience with SQL and NoSQL databases like MySQL, MongoDB, etc. Understanding of ORM (like Sequelize) or ODM (like Mongoose) would be a plus.",
   "Proficiency with Git for version control is required.",
   "Knowledge of testing frameworks like Jest or Mocha is required. Familiarity with debugging tools and practices is essential."
  ],
  bonus_point: [
    "Experience with AWS, Google Cloud, or Azure. Familiarity with services like EC2, S3, Lambda, and DynamoDB would be especially valuable.",
    "Familiarity with Docker, Kubernetes, or other containerization technologies. Experience with CI/CD pipelines would also be a significant plus.",
    "Familiarity with the Next.js framework for building server-side rendered and static web applications.",
    "Understanding of how to measure and improve web performance.",
    "Experience with React Native for mobile application development or knowledge of other mobile development frameworks.",
    "Experience in leading a team, mentoring junior developers, or a track record of strong collaboration and leadership in team environments.",
    "Excellent written and verbal communication skills, and the ability to clearly articulate complex technical concepts to both technical and non-technical colleagues.",
    "Good experience of Project Management, coordinating with different teams, meeting deadlines and delivering under pressure.",
    "Remote Working Experience: Ability to efficiently work in a remote setting."
  ],
};

export const graphicVacancy = {
  title: "UI/UX Designer",
  overview:
    "We are in search of a UI/UX Designer who can make our web application interfaces both appealing and user-friendly. Your role will involve making our websites enjoyable and simple to use, ensuring they look good and are easy to navigate. In addition, you'll be creating vibrant and interesting graphics for our social media posts, helping us stand out online. If you are passionate about creating designs which are both visually pleasing and comfortable to use, we'd love to hear from you.",
  resp: [
    "Designing intuitive and user-friendly interfaces for our digital products.",
    "Developing wireframes and task flows based on user needs.",
    "Implement and refine UI elements such as menus, tabs, and widgets for optimal navigational efficiency.",
    "Conducting layout adjustments based on user feedback.",
    "Creating unique, engaging, and shareable graphics for various social media platforms.",
    "Gather and incorporate feedback from users to improve layout and design.",
    "Working closely with our marketing team to ensure all social media graphics align with our campaigns and overall brand messaging.",
    "Identifying and troubleshooting UX problems (e.g., responsiveness)."
  ],
  req_exp: [
    "Proven work experience as a UI/UX Designer or similar role.",
    "Portfolio of design projects demonstrating both UI/UX and social media graphic design competencies.",
    "Familiarity with design software and technologies (such as InVision, Sketch, Illustrator, Photoshop, Figma etc.)",
    "Team spirit; strong communication skills to collaborate with various stakeholders, both written and verbal.",
    "Good time-management skills.",
    "A keen eye for aesthetics and details.",
    "Ability to work methodically and meet deadlines."
  ],
  bonus_point: [
    "Academics in Computer Science, or a relevant field is preferred.",
    "Ability to efficiently work in a remote setting.",
    "A strong content creation ability is highly preferred."
  ],
};
