import NavBar from "../NavBar";
import VacancyLandPage from "./VacancyLandPage";
import VacancyOverview from "./VacancyOverview";
import KeyResp from "./KeyResp";
import ReqExp from "./ReqExp";
import ApplyVacancy from "./ApplyVacancy";
import Footer from "../Footer";

const VacancyDetail = ({ vacancy }) => {
  return (
    <div className="land-home-head">
      <NavBar />
      <VacancyLandPage vacancy={vacancy} />
      <VacancyOverview vacancy={vacancy} />
      <KeyResp vacancy={vacancy} />
      <ReqExp vacancy={vacancy} />
      <ApplyVacancy />
      <Footer />
    </div>
  );
};

export default VacancyDetail;
