import full_stack_vacancy from "../../images/full-stack-vacancy.png";
import { fullStackVacancy, graphicVacancy } from "../../static_data/data";
import graphic_vacancy from "../../images/graphic-vacancy.png";

const VacancyOverview = ({vacancy}) => {
  return (
    <div className="xl:px-40 lg:py-20 lg:px-20 md:px-12 md:py-16 px-8 py-12 bg-gray-50">
      <div className="grid lg:grid-cols-2 md:grid-cols-1">
        <div>
          <h4 className="text-3xl">Overview</h4>
          <p className="mt-7 text-gray-700">
            {vacancy.overview}
          </p>
        </div>
        {vacancy===fullStackVacancy && <div className="flex lg:justify-end justify-center mt-8 lg:mt-0">
          <img
            src={full_stack_vacancy}
            alt="overview"
            width="400"
          />
        </div>}
        {vacancy===graphicVacancy && <div className="flex lg:justify-end justify-center mt-8 lg:mt-0">
          <img
            src={graphic_vacancy}
            alt="overview"
            width="400"
          />
        </div>}
      </div>
    </div>
  );
};

export default VacancyOverview;
