import { aboutInfo } from "../static_data/data";
import { useState } from "react";
import LearnButton from "../UI/LearnButton";
import Accordion from "./Accordion";
import { motion } from "framer-motion";
const AboutUs = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const showAccordion = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const variants = {
    hidden: {
      x: 140,
    },
    show: {
      x: 0,
    },
  };

  const next_variant = {
    initial: {
      y: 150,
    },
    final: {
      y: 0,
    },
  };


  return (
    <div className="xl:px-40 lg:px-20 md:px-12 px-8 pb-20 pt-8 xl:pt-0 overflow-hidden" id="about-us">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-12">
        <motion.div
          variants={next_variant}
          initial="initial"
          whileInView="final"
          viewport={{ once: true }}
          transition={{ y: { duration: 1 } }}
        >
          <div>
            <div className="my-theme-color font-medium tracking-widest py-3">
              <h4>QUARTZ TECHNOLOGY</h4>
            </div>
            <div className="land-home-head md:text-3xl text-2xl leading-normal">
              <h2>
              Established with the mission to help nepali businesses grow with data. 
              </h2>
            </div>
            <div className="land-home-head text-gray-700 mt-12">
              <p>
              Quartz Technology is a vibrant startup rooted in Nepal, 
              committed to transforming the local business landscape. 
              We blend our understanding of local needs with our technological 
              expertise to create problem-solving softwares that elevate business operations.
              </p>
            </div>
            <div className="land-home-head text-gray-700 mt-6">
              <p>
               We aim to make digital technology accessible and beneficial for all businesses, large and small.
               In today's fast-paced and constantly evolving digital world, data is the cornerstone of any successful enterprise. The ability 
               to access, analyze, and apply insights from data is no longer a luxury—it's a necessity.
               We understand that this digital transition can be complex and challenging, 
               especially for local businesses that have traditionally operated offline.
              </p>
              <p className="mt-6">
               Our aim is to simplify this process, providing the tools, guidance, 
               and support necessary to bridge the gap between traditional operations and modern, data-driven practices.
              </p>
            </div>
            <LearnButton
              className="mt-8 mb-4 lg:mb-0"
              classOfBtn="learn-button"
            />
          </div>
        </motion.div>
        <div className="">
          {aboutInfo.map((info, index) => {
            return (
              <motion.div
                initial="hidden"
                key={index}
                whileInView="show"
                viewport={{ once: true }}
                variants={variants}
                transition={{
                  type: "spring",
                  stiffness: 100,
                  x: { duration: info.delayInAnimation },
                }}
              >
                <Accordion
                  heading={info.heading}
                  info={info.info}
                  index={index}
                  icon={info.icon}
                  showAccordion={showAccordion}
                  activeIndex={activeIndex}
                />
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
