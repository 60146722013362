import { Collapse } from "react-collapse";
import { IoIosArrowDown } from "react-icons/io";

const Accordion = ({
  index,
  activeIndex,
  showAccordion,
  heading,
  info,
  icon,
 className
}) => {
  return (
    <div
      className={`land-home-head bg-gray-50 cursor-pointer mt-6 accordion_header ${
        index === activeIndex && "shadow-[2px_5px_8px] shadow-gray-200"
      } ${className}`}
    >
      <div
        className="flex items-center justify-between p-8 accordion_header"
        onClick={() => showAccordion(index)}
      >
        <div className="flex items-center">
          <div className="my-theme-color text-2xl">{icon}</div>
          <div className="text-lg font-semibold tracking-wider px-4">
            <h3>{heading}</h3>
          </div>
        </div>
        <div className="text-2xl">
          <IoIosArrowDown />
        </div>
      </div>
      <Collapse isOpened={index === activeIndex}>
        <div className="px-6 pb-8 text-sm break-all text-neutral-600 accordion_header">
          <p>{info}</p>
        </div>
      </Collapse>
    </div>
  );
};

export default Accordion;
