import { createContext, useState } from "react";

const StateContext = createContext({
  isOpened: false,
  isClicked: false,
  barIsOpened: false,
  navOptionHandler: () => {},
  navCloseHandler: () => {},
  showAvailableBar: () => {},
  hideAvailableBar: () => {},
});

export const StateContextProvider = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [barIsOpened, setBarIsOpened] = useState(false);

  const navToogleHandler = () => {
    setIsOpened((prevState) => !prevState);
    setIsClicked(true);
  };

  const navCloseHandler = () => {
    setIsOpened(false);
  };

  const showAvailableBar = () => {
    setBarIsOpened(true);
  };

  const hideAvailableBar = () => {
    setBarIsOpened(false);
  };

  const passValue = {
    isOpened,
    navToogleHandler,
    navCloseHandler,
    isClicked,
    showAvailableBar,
    hideAvailableBar,
    barIsOpened,
  };
  return (
    <StateContext.Provider value={passValue}>{children}</StateContext.Provider>
  );
};

export default StateContext;
