import LearnButton from "./LearnButton";

const SlideComp = ({ className, title, info, date, status, link }) => {
  return (
    <div
      className={`bg-gray-50 mt-10 land-home-head px-10 md:h-56 h-80 pt-6 shadow-[0px_0px_2px_lightgray] ${className}`}
    >
      <div>
        <div className="text-2xl font-bold">
          <h3>{title}</h3>
        </div>
        <div className="mt-1">
          <p>{date}</p>
        </div>
        <div className="mt-4 text-sm text-gray-600">
          <p>{info}</p>
        </div>
        {status !== "Recruited" && (
          <div className="mt-6">
            <LearnButton classOfBtn="learn-button" link={link} />
          </div>
        )}
        {status === "Recruited" && (
          <div className="mt-6 text-lg text-white ">
            <button className="bg-gradient-to-r from-[#fc0235] to-black px-4" disabled={true}>Recruited</button>
          </div>
        )}
      </div>
    </div>
  );
};
export default SlideComp;
