import background from "../../images/home-3.jpg";

const VacancyLandPage = ({vacancy}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }} 
      className="h-[calc(100vh-300px)] relative"
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-brightness-50 bg-black/40 z-10"></div>
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50">
        <div className="text-5xl font-bold text-heading md:whitespace-nowrap">
          <h3>{vacancy.title}</h3>
        </div>
      </div>
    </div>
  );
};

export default VacancyLandPage;
