import { Fragment } from "react";
import SlideComp from "../UI/SlideComp";
import SlidePrevButton from "../UI/SlidePrevButton";
import SlideNextButton from "../UI/SlideNextButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { careerInfo } from "../static_data/data";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const CareerSlide = () => {
  return (
    <Fragment>
      <div className="bg-white md:pt-20 pt-16 pb-20 md:pb-36 xl:px-40 lg:px-20 md:px-12 px-8" id="careers">
        <div className="grid md:grid-cols-2 grid-cols-1 land-home-head">
          <div>
            <div className="my-theme-color font-semibold tracking-wider">
              <h3>CAREER</h3>
            </div>
            <div className="text-3xl tracking-wide mt-2">
              <h2>Work with us</h2>
            </div>
          </div>
          <div className="text-center border border-red-600 z-10 text-sm my-theme-color hover:text-white font-semibold md:justify-self-end mt-6 relative">
           
            <button className="uppercase w-full px-12 py-3 view-btn" onClick={() => window.location.href='mailto:info@quartz-technology.com.np?subject=CV Submission&body=Attach your CV and coverletter here.'}>
    Send us your cv
</button>

          </div>
        </div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={32}
          slidesPerView={2.5}
          pagination={{ clickable: true, el: ".my-custom-pagination" }}
          breakpoints={{
            320: {
              slidesPerView: 1
            },
            768: {
              slidesPerView: 1.5
            },
            1440: {
              slidesPerView: 2.5
            }
          }}
          style={{
            "--swiper-pagination-color": "#fc0235",
            "--swiper-pagination-bullet-inactive-color": "#999999",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
            "--swiper-pagination-bullet-horizontal-gap": "6px",
          }}
        >
          {careerInfo.map((career, index) => {
            return (
              <SwiperSlide key={index}>
                {({ isActive }) => (
                  <SlideComp
                    isActive={isActive}
                    title={career.title}
                    date={career.date}
                    info={career.info}
                    status={career.status}
                    link={career.link}
                  />
                )}
              </SwiperSlide>
            );
          })}
          <div className="flex">
            <div className="flex justify-around mt-8 items-center">
              <div>
                <SlidePrevButton />
              </div>
              <span className="my-custom-pagination"></span>
              <div className="px-6">
                <SlideNextButton/>
              </div>
            </div>
          </div>
        </Swiper>
      </div>
    </Fragment>
  );
};
export default CareerSlide;
