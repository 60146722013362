import { useState } from "react";
import { BsWindowDock } from "react-icons/bs";
import { FaServer} from "react-icons/fa";
import { FiDatabase } from "react-icons/fi";
import { AiOutlineAntDesign, AiOutlineCloudServer } from "react-icons/ai";
import WorkSkill from "./WorkSkill";
import ClickWork from "./ClickWork";

const AllWork = () => {
  let initialState = {
    frontend: true,
    backend: false,
    // mobile: false,
    database: false,
    server: false,
    design: false,
  };

  const [isActive, setIsActive] = useState(initialState);

  const activationHandler = (clicked) => {
    initialState = { ...initialState, frontend: false };
    setIsActive(() => {
      return { ...initialState, [clicked]: true };
    });
  };

  return (
    <div className="xl:px-40 lg:px-20 md:px-12 px-8 md:py-20 py-16 land-home-head bg-gray-50">
      <div>
        <div className="uppercase tracking-wider my-theme-color font-semibold">
          <h3>Work with us</h3>
        </div>
        <div className="mt-4 text-3xl">
          <h2>Our field of expertise</h2>
        </div>
      </div>
      <div className="md:flex">
        <div className="text-xl text-gray-600 mt-16">
          <ClickWork
            isActive={isActive}
            skill="frontend"
            icon={<BsWindowDock />}
            activationHandler={activationHandler}
          />
          <ClickWork
            isActive={isActive}
            skill="backend"
            icon={<FaServer />}
            activationHandler={activationHandler}
          />
          {/* <ClickWork
            isActive={isActive}
            skill="mobile"
            icon={<FaMobile />}
            activationHandler={activationHandler}
          /> */}
          <ClickWork
            isActive={isActive}
            skill="database"
            icon={<FiDatabase />}
            activationHandler={activationHandler}
          />
          <ClickWork
            isActive={isActive}
            skill="server"
            icon={<AiOutlineCloudServer />}
            activationHandler={activationHandler}
          />
          <ClickWork
            isActive={isActive}
            skill="design"
            icon={<AiOutlineAntDesign />}
            activationHandler={activationHandler}
          />
        </div>
        <div className="xl:ml-40 md:ml-20 lg:ml-28 pt-16">
          <WorkSkill isActive={isActive} position={0} skill="frontend" />
          <WorkSkill isActive={isActive} position={1} skill="backend" />
          <WorkSkill isActive={isActive} position={3} skill="design" />
          <WorkSkill isActive={isActive} position={2} skill="database" />
          {/* <WorkSkill isActive={isActive} position={4} skill="mobile" /> */}
          <WorkSkill isActive={isActive} position={4} skill="server" />
        </div>
      </div>
    </div>
  );
};

export default AllWork;
