import { Fragment } from "react";
import { workLogo } from "../static_data/data";
import { motion } from "framer-motion";
import { Collapse } from "react-collapse";
const WorkSkill = ({ isActive, position, skill }) => {
  const variants = {
    initial: { x: 50 },
    final: { x: 0 },
  };
  return (
    <Fragment>
      <Collapse isOpened={isActive[skill]}>
        <motion.div
          variants={variants}
          initial="initial"
          animate="final"
          transition={{
            type: "spring",
            stiffness: 100,
            ease: "linear",
          }}
        >
          <div className="grid grid-cols-4 gap-y-10 place-items-center">
            {workLogo[position][skill].map((log, index) => {
              return (
                <div key={index}>
                  <img src={log} alt="skill"></img>
                </div>
              );
            })}
          </div>
        </motion.div>
      </Collapse>
    </Fragment>
  );
};

export default WorkSkill;
