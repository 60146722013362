import erp from "../images/ERP.png";
import { useContext } from "react";
import StateContext from "../context/StateContext";

const Product = () => {
  const { showAvailableBar } = useContext(StateContext);
  return (
    <div className="xl:px-40 lg:px-20 md:px-12 px-8 md:py-20 py-16">
      <div className="grid lg:grid-cols-2 grid-cols-1 land-home-head">
        <div>
          <div className="md:text-3xl text-2xl xl:pr-20">
            <h2>
            Streamline your business operations with our comprehensive ERP software designed to empower our local SMEs. 
            </h2>
          </div>
          <div className="mt-8">
            <ol>
              <li>
                1. Can easily generate sales invoices and auto-generate account
                statements.
              </li>
              <li className="mt-2">
                2. Can easily handle your customers and vendors credit accounts.
              </li>
              <li className="mt-2">
                3. Can manage your products stocks history.
              </li>
              <li className="mt-2 lg:pr-8">
                4. Have an ability to auto-generate business reports and
                statistics highlighting key subjects like Balance Sheet,
                Turnovers, Profits, Expenses, Credits to give and receive, Cash
                Flow etc.
              </li>
              <li className="mt-2">
                5. Can send bulk messages to your clients for your marketing announcements. 
              </li>
            </ol>
          </div>
          <div className="md:flex mt-8">
            <div
              className="relative border border-red-600 text-center"
              onClick={showAvailableBar}
            >
              <button
                type="button"
                className="text-white px-4 py-3 hover:text-red-600 long-hire-button-2"
              >
                Take a Product Tour
              </button>
            </div>
          </div>
        </div>
        <div className="mt-12 lg:mt-0">
          <img src={erp} alt="our-software" />
        </div>
      </div>
    </div>
  );
};

export default Product;
