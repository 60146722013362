import { useContext } from "react";
import { navOptions } from "../static_data/data";
import { motion } from "framer-motion";
import StateContext from "../context/StateContext";

const MobileNav = () => {
  const { navCloseHandler } = useContext(StateContext);
  const variants = {
    from: { x: 100 },
    to: { x: 0 },
  };
  return (
    <motion.div
      variants={variants}
      initial="from"
      animate="to"
      transition={{ type: "tween" }}
    >
      <div className="px-8 md:px-12 z-50 h-screen fixed right-0 z-50 w-full lg:hidden bg-gradient-to-l from-black to-transparent">
        <div className="land-home-head font-semibold text-2xl uppercase text-gray-200">
          <nav className="text-right mt-20">
            <ul>
              {navOptions.map((options, index) => {
                return (
                  <li key={index} className="py-3" onClick={navCloseHandler}>
                    <a className="p-2 block md:hidden" href={options.link.mobile}>
                      {options.name}
                    </a>
                    <a className="p-2 md:block hidden lg:hidden" href={options.link.pc}>
                      {options.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </motion.div>
  );
};

export default MobileNav;
