import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import { FiMapPin, FiPhoneCall } from "react-icons/fi";
import { BsEnvelope } from "react-icons/bs";
import ContactOption from "../UI/ContactOption";
import Input from "../UI/Input";

const ContactUs = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  //validation part
  const validateForm = () => {
    let errors = {};
    let valid = true;

    if (!form.name) {
      errors.name = "Name is required";
      valid = false;
    }

    if (!form.email) {
      errors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = "Email is invalid";
      valid = false;
    }
    if (!form.subject) {
      errors.subject = "Subject is required";
      valid = false;
    }

    if (!form.message) {
      errors.message = "Message is required";
      valid = false;
    }

    setErrors(errors);
    setIsValid(valid);

    return valid;
  };

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });

    let errors = {};
    let valid = true;

    if (name === "name" && !value) {
      errors.name = "Name is required";
      valid = false;
    }

    if (name === "email" && !value) {
      errors.email = "Email is required";
      valid = false;
    } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      errors.email = "Email is invalid";
      valid = false;
    }

    if (name === "subject" && !value) {
      errors.subject = "Subject is required";
      valid = false;
    }

    if (name === "message" && !value) {
      errors.message = "Message is required";
      valid = false;
    }

    setErrors(errors);
    setIsValid(valid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);

      emailjs
        .send(
          "service_16glwnc",
          "template_9n5t8xw",
          {
            from_name: form.name,
            to_name: "Quartz Technology",
            from_email: form.email,
            to_email: "quartz.technology101@gmail.com",
            message: form.message,
          },
          "SUrZqiUdHYEc-khaJ"
        )
        .then(
          () => {
            setLoading(false);
            alert("Thank you. We will get back to you as soon as possible.");

            setForm({
              name: "",
              email: "",
              subject: "",
              message: "",
            });
          },
          (error) => {
            setLoading(false);
            console.error(error);

            alert("Ahh, something went wrong. Please try again.");
          }
        );
    }
  };

  return (
    <div
      className="xl:px-40 lg:px-20 md:px-12 px-8 lg:py-20 py-16 land-home-head"
      id="contact-us"
    >
      <div className="grid lg:grid-cols-2 grid-cols-1 xl:gap-x-24 lg:gap-x-12">
        <div>
          <div className="uppercase tracking-wider my-theme-color font-semibold">
            <h3>CONTACT US</h3>
          </div>
          <div className="mt-2 text-3xl leading-snug">
            <h2>If you have any queries or need any help, write to us</h2>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 mt-7">
            <ContactOption
              icon={<BsEnvelope />}
              title="Email"
              info="info@quartz-technology.com.np"
            />
            <ContactOption
              icon={<FiMapPin />}
              title="Kathmandu Office"
              info="Baniyatar, Tokha, Nepal"
              className="col-start-1"
            />
            <ContactOption
              icon={<FiMapPin />}
              title="Chitwan Office"
              info="Gaindakot-2, Nawalpur"
            />
            <ContactOption
              icon={<FiPhoneCall />}
              title="Phone"
              info="+977-9864280323"
            />
            {/* <ContactOption
              icon={<FiPhoneCall />}
              title="Us Phone"
              info="+1 (408) 594 5083"
            /> */}
          </div>
        </div>
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="mt-16">
            <div className="flex">
              <div>
                <Input
                  type="text"
                  placeholder="Full Name"
                  className="w-full"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  error={errors.name}
                />
                {errors.name && (
                  <span className="text-red-500">{errors.name}</span>
                )}
              </div>
              <div>
                <Input
                  type="email"
                  placeholder="Email Address"
                  className="w-full pl-4"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  error={errors.email}
                />
                {errors.email && (
                  <span className="text-red-500 ml-4">{errors.email}</span>
                )}
              </div>
            </div>
            <div>
              <Input
                type="text"
                placeholder="Subject"
                className="col-span-2 col-start-1 mt-6"
                name="subject"
                value={form.subject}
                onChange={handleChange}
                error={errors.subject}
              />
              {errors.subject && (
                <span className="text-red-500">{errors.subject}</span>
              )}
            </div>
            <div>
              <textarea
                placeholder="Write a message"
                className={`h-40 bg-gray-50 w-full mt-6 p-4 border ${
                  errors.message ? "border-red-500" : "border-gray-300"
                } `}
                name="message"
                value={form.message}
                onChange={handleChange}
                error={errors.message}
              ></textarea>
              {errors.message && (
                <span className="text-red-500">{errors.message}</span>
              )}
            </div>

            <div className="mt-4 relative md:inline-block text-center border border-red-600">
              <button
                type="submit"
                className="uppercase text-white hover:text-red-600 px-12 py-3 text-sm font-semibold long-hire-button-2"
              >
                {loading ? "Sending..." : "Send Message"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
