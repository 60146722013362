import ConceptMap from "./ConceptMap";

const ReqExp = (props) => {
  return (
    <div className="xl:px-40 lg:py-20 lg:px-20 md:px-12 md:py-16 px-8 py-12 bg-gray-50">
      <div className="text-3xl">
        <h3>Required Experience</h3>
      </div>
      <div className="">
        <ConceptMap vacancy={props.vacancy} special="req_exp" />
      </div>
    </div>
  );
};

export default ReqExp;
