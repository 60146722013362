import { FaArrowUp } from "react-icons/fa";


const NavigateTop = () => {
  return (
    <div>
      <a href="#navbar" className="lg:block hidden">
        <div className="fixed right-0 bottom-0 bg-[#fc0235] text-white text-xl p-6 z-50 cursor-pointer">
          <FaArrowUp />
        </div>
      </a>
      <a href="#headbar" className="block lg:hidden">
        <div className="fixed right-0 bottom-0 bg-[#fc0235] text-white text-xl p-6 z-50 cursor-pointer">
          <FaArrowUp />
        </div>
      </a>
    </div>
  );
};

export default NavigateTop;
