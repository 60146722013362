import { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import StateContext from "../context/StateContext";

const NotAvailable = () => {
  const { hideAvailableBar } = useContext(StateContext);
  return (
    <div className="fixed top-0 z-[99] w-full xl:px-40 md:px-12 lg:px-20 py-2 px-8 bg-[#fc0235] text-heading shadow-lg">
      <div className="flex justify-between items-center">
        <div className="text-lg text-white ">
          <p>Currently not available!</p>
        </div>
        <div className="text-2xl text-white">
          <button onClick={hideAvailableBar}>
            <FaTimes />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotAvailable;
