import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { HiOutlineLightBulb } from "react-icons/hi";
import {
  BsCloudSnow,
  BsTerminal,
  BsCalendarEvent,
  BsRocketTakeoff,
} from "react-icons/bs";
import {AiOutlineArrowRight} from "react-icons/ai"
import "react-vertical-timeline-component/style.min.css";

const MobDBS = () => {
  return (
    <div className="why-quartz-dbs px-8 pt-12 pb-12 md:hidden relative block">
      <div className="text-gray-100 md:px-12 pb-12">
        <div className="md:pr-10">
          <div className="text-3xl">
            <h2>How It Works?</h2>
          </div>
          <div className="mt-4">
            <p>
              We simflify operations through a three-step process: Investigation
              of business needs, Customized system design and Seamless scaling.
              By understanding your requirements, we create a tailored solution
              that integrates finance, inventory and sales enabling efficient
              information flow and adaptability as your business expands.
            </p>
          </div>
        </div>
        <div className="md:mt-4 mt-12 whitespace-nowrap text-xs">
          <a href="#contact-us">
            <button className="uppercase border transition duration-500 button-bg-color hover:bg-transparent hover:border-red-600 text-white p-3 font-semibold flex items-center">
              Contact us
              <span className="pl-3">
                <AiOutlineArrowRight />
              </span>
            </button>
          </a>
        </div>
      </div>
      <VerticalTimeline
        lineColor="gray"
        layout="1-column-left"
        className="md:hidden"
      >
        <VerticalTimelineElement
          className="vertical-timeline-element--work border-bottom-4 border-red-500"
          contentStyle={{
            background: "rgba(0,0,0,0.5)",
            color: "#fff",
            width: "250px",
          }}
          contentArrowStyle={{ borderRight: "7px solid  white" }}
          iconStyle={{ background: "rgba(0,0,0,0.5)", color: "#fff" }}
          icon={<HiOutlineLightBulb />}
          iconClassName="border-4 border-red-500"
        >
          <h3 className="vertical-timeline-element-title text-xl">Investigate</h3>
          {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4>
        <p>
          Creative Direction, User Experience, Visual Design, Project
          Management, Team Leading
        </p> */}
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: "rgba(0,0,0,0.5)",
            color: "#fff",
            width: "250px",
          }}
          contentArrowStyle={{ borderRight: "7px solid white" }}
          iconStyle={{ background: "rgba(0,0,0,0.5)", color: "#fff" }}
          icon={<BsCloudSnow />}
          iconClassName="border-4 border-red-500"
        >
          <h3 className="vertical-timeline-element-title text-xl">Design</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "rgba(0,0,0,0.5)", color: "#fff" }}
          contentStyle={{
            background: "rgba(0,0,0,0.5)",
            color: "#fff",
            width: "250px",
          }}
          contentArrowStyle={{ borderRight: "7px solid white" }}
          icon={<BsTerminal />}
          iconClassName="border-4 border-red-500"
        >
          <h3 className="vertical-timeline-element-title text-xl">Develop</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "rgba(0,0,0,0.5)", color: "#fff" }}
          contentStyle={{
            background: "rgba(0,0,0,0.5)",
            color: "#fff",
            width: "250px",
          }}
          contentArrowStyle={{ borderRight: "7px solid white" }}
          icon={<BsRocketTakeoff />}
          iconClassName="border-4 border-red-500"
        >
          <h3 className="vertical-timeline-element-title text-xl">Launch</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          iconStyle={{ background: "rgba(0,0,0,0.5)", color: "#fff" }}
          contentStyle={{
            background: "rgba(0,0,0,0.5)",
            color: "#fff",
            width: "250px",
          }}
          contentArrowStyle={{ borderRight: "7px solid white" }}
          icon={<BsCalendarEvent />}
          iconClassName="border-4 border-red-500"
        >
          <h3 className="vertical-timeline-element-title text-xl">Scale</h3>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default MobDBS;
