const Input = ({className, inputClass, type, placeholder, name, onChange, value, error}) => {
  return (
    <div className={className}>
      <input
        type={type}
        placeholder={placeholder}
        className={`bg-gray-50 w-full h-12 px-4 border ${error ? "border-red-500" :"border-gray-300"} ${inputClass}`}
        onChange={onChange}
        value={value}
        name={name}
      />
    </div>
  );
};

export default Input;
