import { FaCheckCircle } from "react-icons/fa";

const ConceptMap = ({ vacancy, special }) => {
  return (
    <div
      className={`${
        special === "req_exp" ? "grid lg:grid-cols-2 grid-cols-1" : ""
      } gap-40 mt-7`}
    >
      <div
        className={`${
          special === "resp" ? "grid lg:grid-cols-2 grid-cols-1 gap-x-40" : ""
        }`}
      >
        {vacancy[special].map((keyConcept, index) => {
          return (
            <div className="flex md:py-2 py-2" key={index}>
              <div className="text-[#fc0235]">
                <FaCheckCircle />
              </div>
              <div className="ml-3">
                <p>{keyConcept}</p>
              </div>
            </div>
          );
        })}
      </div>
      {special === "req_exp" && (
        <div className="lg:-mt-16 -mt-32 md:-mt-28">
          <div className="text-3xl">
            <h3>Bonus Points</h3>
          </div>
          <div className="mt-6">
            {vacancy["bonus_point"].map((keyConcept, index) => {
              return (
                <div className="flex py-2" key={index}>
                  <div className="text-[#fc0235]">
                    <FaCheckCircle />
                  </div>
                  <div className="ml-3">
                    <p>{keyConcept}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConceptMap;
