const ApplyVacancy = () => {
  return (
    <div className="xl:px-40 lg:py-20 lg:px-20 md:px-12 md:py-16 px-8 py-12 content-center bg-[rgba(0,0,0,0.7)] mb-20">
      <div className="grid md:grid-cols-7 grid-cols-1 text-gray-200">
        <div className="col-span-2 text-3xl">
          <h4>To apply</h4>
        </div>
        <div className="col-span-3 mt-4 md:mt-0">
          <p>
            Send your CV, a snappy cover letter which highlights your expertise,
            skills and experience and any relevant links/attachments to your
            work.
          </p>
        </div>
        <div className="md:justify-self-end col-span-2 mt-4 md:mt-0">
          <div className="relative border border-white text-center z-50">
            <button
              type="button"
              className="text-white px-10 py-3 hover:text-red-600 long-hire-button"
              onClick={()=>window.location.href="mailto:info@quartz-technology.com.np?subject=Job Application&body=Attach your documents here."}
            >
              Apply Here
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyVacancy;
