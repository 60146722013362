import { Fragment, useContext } from "react";
import logo from "../images/logo.png";
import { navOptions } from "../static_data/data";
import { FaBars } from "react-icons/fa";
import MobileNav from "../UI/MobileNav";
import StateContext from "../context/StateContext";
import { motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
import NotAvailable from "./NotAvailable";
import { useLocation } from "react-router-dom";
import { BsBoxArrowLeft } from "react-icons/bs";

const NavBar = () => {
  const navContext = useContext(StateContext);
  const { isOpened, isClicked, navToogleHandler, barIsOpened } = navContext;
  const variants = {
    initial: { x: 0 },
    final: { x: 2000 },
  };
  const location = useLocation();
  return (
    <Fragment>
      {barIsOpened && <NotAvailable />}
      <div
        className="xl:px-40 px-20 py-6 bg-white sticky top-0 z-50 shadow-md lg:block hidden"
        id="navbar"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img src={logo} alt="logo" width={40} />
            <span className="text-lg font-semibold px-2 uppercase">
              Quartz Technology
            </span>
          </div>
          <div>
            <nav>
              <ul className="flex items-center text-md">
                {location.pathname === "/" &&
                  navOptions.map((option, index) => {
                    return (
                      <li
                        className="uppercase px-4 cursor-pointer nav-list-option"
                        key={index}
                      >
                        <a href={option.link.pc}>{option.name}</a>
                      </li>
                    );
                  })}
                {(location.pathname === "/vacancy/full-stack" ||
                  location.pathname === "/vacancy/graphic-designer") && (
                  <a href="/">
                    <li className="flex items-center">
                      <div className="text-2xl">
                        <BsBoxArrowLeft />
                      </div>
                      <div className="pl-2">GO TO HOMEPAGE</div>
                    </li>
                  </a>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {isClicked && isOpened && <MobileNav />}
      {isClicked && !isOpened && (
        <motion.div
          variants={variants}
          initial="initial"
          animate="final"
          transition={{
            type: "spring",
            stiffness: 100,
            ease: "linear",
            x: { duration: 2 },
          }}
        >
          <MobileNav />
        </motion.div>
      )}

      <div
        className="land-home-head py-6 px-8 md:px-12 block lg:hidden"
        id="headbar"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div>
              <img src={logo} alt="logo" width={40} />
            </div>
            <div className="text-lg uppercase font-bold text-gray-700 px-2">
              <h2>Quartz technology</h2>
            </div>
          </div>
          {location.pathname === "/" && (
            <div
              className={`text-3xl font-bold bg-gray-800 text-white p-1 rounded-full ${
                isOpened ? "fixed right-8 md:right-12 z-50" : "relative "
              }`}
              onClick={navToogleHandler}
            >
              {!isOpened && !isClicked && <FaBars />}
              {!isOpened && isClicked && <FaBars />}
              {isOpened && isClicked && <RxCross1 />}
            </div>
          )}
          {(location.pathname === "/vacancy/full-stack" ||
            location.pathname === "/vacancy/graphic-designer") && (
            <div>
              <a href="/">
                <li className="flex items-center">
                  <div className="text-2xl">
                    <BsBoxArrowLeft />
                  </div>
                  <div className="pl-2">HOMEPAGE</div>
                </li>
              </a>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default NavBar;
