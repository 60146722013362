import { AiOutlineArrowRight } from "react-icons/ai";
import { HiOutlineLightBulb } from "react-icons/hi";
import {
  BsCloudSnow,
  BsTerminal,
  BsCalendarEvent,
  BsRocketTakeoff,
} from "react-icons/bs";
import attach_bg from "../images/dbs.png";
const DBS = () => {
  return (
    <div
      className="xl:px-40 lg:px-20 md:px-12 px-8 md:py-28 py-16 land-home-head brightness-90 md:block hidden"
      style={{
        backgroundImage: `url(${attach_bg})`,
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="md:flex text-gray-100">
        <div className="lg:pr-56 md:pr-10">
          <div className="text-3xl">
            <h2>How It Works?</h2>
          </div>
          <div className="mt-4">
            <p>
            We simflify operations through a three-step process: 
            Investigation of business needs, Customized system design and Seamless scaling. 
            By understanding your requirements, we create a tailored solution that integrates finance, 
            inventory and sales enabling efficient information flow and adaptability as your business expands.
            </p>
          </div>
        </div>
        <div className="md:mt-4 mt-12 whitespace-nowrap text-xs">
          <a href="#contact-us">
            <button className="uppercase border transition duration-500 button-bg-color hover:bg-transparent hover:border-red-600 text-white p-3 font-semibold flex items-center">
              Contact us
              <span className="pl-3">
                <AiOutlineArrowRight />
              </span>
            </button>
          </a>
        </div>
      </div>
      <div className="md:grid md:grid-cols-5 md:border-t md:border-dashed md:mt-32 mt-24">
        <div className="md:-mt-12 flex justify-center">
          <div>
            <div className="bg-attach text-white text-2xl px-12 py-9 rounded-full text-gray-600">
              <p>1</p>
            </div>
            <div className="flex justify-center text-4xl mt-2 text-white">
              <HiOutlineLightBulb />
            </div>
            <div className="text-xl mt-4 text-center text-gray-100">
              <h3>Investigate</h3>
            </div>
          </div>
        </div>
        <div className="md:-mt-12 mt-8 flex justify-center">
          <div>
            <div className="bg-attach text-white text-2xl px-12 py-9 rounded-full text-gray-600">
              <p>2</p>
            </div>
            <div className="flex justify-center text-4xl mt-2 text-white">
              <BsCloudSnow />
            </div>
            <div className="text-xl mt-4 text-center text-gray-100">
              <h3>Design</h3>
            </div>
          </div>
        </div>
        <div className="md:-mt-12 mt-8 flex justify-center">
          <div>
            <div className="bg-attach text-white text-2xl px-12 py-9 rounded-full text-gray-600">
              <p>3</p>
            </div>
            <div className="flex justify-center text-4xl mt-2 text-white">
              <BsTerminal />
            </div>
            <div className="text-xl mt-4 text-center text-gray-100">
              <h3>Develop</h3>
            </div>
          </div>
        </div>
        <div className="md:-mt-12 mt-8 flex justify-center">
          <div>
            <div className="bg-attach text-white text-2xl px-12 py-9 rounded-full text-gray-600">
              <p>4</p>
            </div>
            <div className="flex justify-center text-4xl mt-2 text-white">
              <BsRocketTakeoff />
            </div>
            <div className="text-xl mt-4 text-center text-gray-100">
              <h3>Launch</h3>
            </div>
          </div>
        </div>
        <div className="md:-mt-12 mt-8 flex justify-center">
          <div>
            <div className="bg-attach text-white text-2xl px-12 py-9 rounded-full text-gray-600">
              <p>5</p>
            </div>
            <div className="flex justify-center text-4xl mt-2 text-white">
              <BsCalendarEvent />
            </div>
            <div className="text-xl mt-4 text-center text-gray-100">
              <h3>Scale</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DBS;
