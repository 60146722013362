import ConceptMap from "./ConceptMap";

const KeyResp = (props) => {
  return (
    <div className="xl:px-40 lg:py-20 lg:px-20 md:px-12 md:py-16 px-8 py-12 bg-[rgba(0,0,0,0.7)]">
      <div className="text-gray-300">
        <div className="text-3xl">
          <h3>Key responsibilities</h3>
        </div>
        <ConceptMap vacancy={props.vacancy} special="resp" />
      </div>
    </div>
  );
};

export default KeyResp;
