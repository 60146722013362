const ClickWork = ({ isActive, skill, icon, activationHandler }) => {
  return (
    <div className={`${isActive[skill] ? "my-theme-color" : ""} py-2.5`}>
      <button onClick={() => activationHandler(skill)}>
        {" "}
        <div className="flex items-center">
          <div
            className={`border-2 ${
              isActive[skill] ? "border-[#fc0235]" : "border-gray-600"
            } p-2 rounded-full`}
          >
            {icon}
          </div>
          <div className="px-2">
            <p>{skill.charAt(0).toUpperCase() + skill.slice(1)}</p>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ClickWork;
