import background from "../images/home-2.jpg";
import cuve from "../images/curve-2.png";
import { useCallback, useContext } from "react";
import { Typewriter } from "react-simple-typewriter";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import StateContext from "../context/StateContext";
const LandHome = () => {
  const particlesInit = useCallback(async (engine) => {
    // console.log(engine);
    await loadFull(engine);
  }, []);
  const particlesLoaded = useCallback(async (container) => {
    // await console.log(container);
  }, []);
  const navContext = useContext(StateContext);
  const { isOpened, isClicked } = navContext;
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          // clipPath: "polygon(0 0,100% 0,100% 75%,50% 100%,0 70%)",
        }}
        className="pt-20 xl:px-40 lg:px-20 px-3.5 md:px-12 md:h-[calc(100vh-80px)] h-[calc(100vh-200px)] relative z-[-1]"
      >
        <div id="particles">
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: false,
                },
                modes: {
                  push: {
                    quantity: 0,
                  },
                  repulse: {
                    distance: 100,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: false,
                },
                move: {
                  directions: "none",
                  enable: true,
                  // outModes: {
                  //   default: "bounce",
                  // },
                  random: true,
                  speed: 6,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 100,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 3 },
                },
              },
              detectRetina: false,
            }}
          />
        </div>
        <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-brightness-50 bg-black/30"></div>
      </div>
      <div className="absolute md:-bottom-3 hidden md:block">
        <img src={cuve} alt="curve" />
      </div>
      <div
        className={`land-home-head absolute md:top-40 xl:left-40 lg:left-20 md:left-12 top-32 left-8 right-8 text-heading md:text-5xl text-4xl font-extrabold grid xl:grid-cols-2 grid-cols-1 lg:pr-96 xl:pr-0 md:pr-20 z-[-1]`}
      >
        <div className="leading-snug tracking-normal">
          <Typewriter
            words={["Your Digital Transformation Partner based in Nepal...","Streamline your business with our technical expertise..."]}
            loop={0}
            cursor
          />
          {/* <h1>Your innovative tech partner from strategy to execution.</h1> */}
        </div>
      </div>
      <div
        className={`text-heading absolute top-[70%] left-8 md:left-12 lg:left-20 xl:left-40
        ${isClicked && isOpened && "z-[-1]"}`}
      >
        <a href="#contact-us">
          <button className="uppercase border border-white px-12 py-4 font-semibold long-hire-button">
            Hire us
          </button>
        </a>
      </div>
    </div>
  );
};

export default LandHome;
